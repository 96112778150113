export const useGlobalStore = defineStore("global", {
  state() {
    return {
      isNewVersion: false,
      isPc: true
    };
  },

  actions: {
    setNewVersion(flag) {
      this.isNewVersion = flag;
    },
    setDeviceType(value) {
      this.isPc = value;
    }
  }
});