import request from "../request";

// 查询地区字典
export function cyberTerritoryDict(data) {
  return request({
    url: "/customer/zipcode/release/cyberTerritoryDict",
    method: "post",
    query: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 根据国家查询州 查询美国州 参数传2 加拿大省 参数传55
export function findProvince(data) {
  return request({
    url: "/customer/zipcode/release/findProvince",
    method: "post",
    query: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 根据邮编获取 国家, 州/省 数据
export function getInfo(data) {
  return request({
    url: "/customer/zipcode/release/getInfo",
    method: "post",
    query: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 生成验证码,并发送邮箱
export function sendVerifyCodeToEmail(data) {
  return request({
    url: "/customer/customer/release/sendVerifyCodeToEmail",
    method: "post",
    body: data
  });
}

// 登录注册验证发送邮件验证码
export function sendLoginVerifyCodeToEmail(data) {
  return request({
    url: "/system/mallLogin/sendVerifyCodeLogin",
    method: "post",
    body: {
      loginName: data.email,
      browserType: data.browserType
    }
  });
}

// 校验邮箱下是否存在客户
export function registerEmailCheck(data) {
  return request({
    url: "/customer/customer/release/registerEmailCheck",
    method: "post",
    body: data
  });
}

// 客户注册
export function registerCustomer(data) {
  return request({
    url: "/customer/customer/release/registerCustomer",
    method: "post",
    body: data,
    headers: {
      encode: true
    }
  });
}

// 客户更新
export function customerEdit(data) {
  return request({
    url: "/customer/customer/customerEdit",
    method: "post",
    body: data,
    headers: {
      encode: true
    }
  });
}

// 发送邮箱重置密码
export function sendEmailUpdatePassword(data) {
  return request({
    url: "/system/mallLogin/sendEmailUpdatePassword",
    method: "post",
    body: data
  });
}

// 重置密码
export function changePassword(data) {
  return request({
    url: "/system/mallLogin/changePassword",
    method: "post",
    body: data
  });
}

// 密码是否失效
export function checkUpdatePasswordLink(data) {
  return request({
    url: "/system/mallLogin/checkUpdatePasswordLink",
    method: "post",
    body: data
  });
}

//上传getSTSToken
export function getSTSToken(data = {}) {
  return request({
    url: "/system/oss/getSTSToken",
    method: "post",
    body: data
  });
}

// 获取类目列表
export function listByTenantId(size) {
  return request({
    url: `/system/home-category/listByTenantId`,
    method: "post",
    body: {size},
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 获取轮播图列表
export function listByTenantIdImg() {
  return request({
    url: "/system/home-carousel/listByTenantId",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 提交留言
export function submit(data) {
  return request({
    url: `/system/contact-us-record/submit`,
    method: "post",
    body: data
  });
}

// 修改密码
export function changePasswordEmail(data) {
  return request({
    url: "/system/mallLogin/changePasswordEmail",
    method: "post",
    body: data,
    headers: {
      encode: true
    }
  });
}

export function sendEmailLogin(data) {
  return request({
    url: "/system/mallLogin/sendEmailLogin",
    method: "post",
    body: data
  });
}

// 客户浏览记录保存
export function saveRecord(data) {
  return request({
    url: "/customer/customer/browsing/saveRecord",
    method: "post",
    body: data
  });
}

// 客户浏览记录查询
export function recordListPage(data) {
  return request({
    url: "/customer/customer/browsing/recordListPage",
    method: "post",
    body: data
  });
}

// 客户浏览记录删除
export function deleteRecord(data) {
  return request({
    url: "/customer/customer/browsing/deleteRecord",
    method: "post",
    body: data
  });
}

// 生成匿名用户唯一标记
export function requestClientId() {
  return request({
    url: "/system/anonymous/requestClientId",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}
// 获取搜索执行版本
export function searchVersion() {
  return request({
    url: "/product/api/v1/product/searchVersion",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
       GrayTag:'gray'
    }
  });
}

// 首页获取印刷图片
export function getPrintImage(data) {
  return request({
    url: "/system/home-product/getPrintImage",
    method: "post",
    body: data
  });
}

// 获取分享产品发送邮件地址
export function getShareProductAccount() {
  return request({
    url: "/system/share-product/getShareProductAccount",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 分享商品账号邮件发送
export function sendShareProductEmail(data) {
  return request({
    url: "/system/share-product/sendShareProductEmail",
    method: "post",
    body: data
  });
}
