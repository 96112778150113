import { useGlobalStore } from "~/stores";
function getDeviceType(userAgent) {
  userAgent = userAgent || navigator.userAgent
  // 检查是否为移动设备
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )
  // 检查是否为平板设备
  const isTablet =
    /Tablet|iPad|PlayBook|Nexus 7|Nexus 10|Kindle|Silk|GT-P1000|GT-P1010|GT-P7500|GT-P7510|SCH-I800M|SHW-M180S|SM-T320|SM-T350|SM-T520|SM-T550|SM-T560|SM-T800|SM-T805|SM-T807|SM-T820|SM-T830|SM-T850|SM-T900|SM-T905|SM-T920|SM-T950|SM-T960|SM-T970|SM-T975|SM-T980|SM-T985|SM-T990|SM-T995|SM-T999|SM-T810|SM-T815|SM-T817|SM-T825|SM-T835|SM-T855|SM-T860|SM-T870|SM-T880|SM-T890|SM-T895|SM-T900|SM-T905|SM-T920|SM-T950|SM-T960|SM-T970|SM-T975|SM-T980|SM-T985|SM-T990|SM-T995|SM-T999/i.test(
      userAgent
    )
  // 如果是移动设备但不是平板设备，则为手机
  if (isMobile && !isTablet) {
    return "Mobile"
  } else if (isTablet) {
    return "Tablet"
  } else {
    return "PC"
  }
}

export default defineNuxtRouteMiddleware(() => {
  const event = useRequestEvent();
  const gStore = useGlobalStore()
  if (event) {
    const userAgent = event.req.headers["user-agent"]
    const deviceType = getDeviceType(userAgent)
    gStore.setDeviceType(deviceType === 'PC')
  }
});
