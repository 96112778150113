<template>
  <div class="project-swiper" :class="{ 'mobile-terminal': !gStore.isPc }">
    <swiper
      loop
      :spaceBetween="0"
      :slidesPerView="1"
      :slidesPerGroup="1"
      :centeredSlides="false"
      :resistance="true"
      :resistanceRatio="0.85"
      @swiper="onSwiper"
    >
      <swiper-slide v-for="(group, i) in groupedData" :key="i">
        <v-card
          v-for="(item, j) in group"
          :key="j"
          color="#fff"
          height="174"
          class="swiper-slide-item"
        >
          <div class="card-top">
            <div class="quotationMark"></div>
            <!-- <v-tooltip
              v-if="gStore.isPc"
              content-class="evaluaterTooltip"
              :text="item.reviewer?.toLocaleUpperCase()"
              location="top"
            >
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="evaluater">{{
                  (item.reviewer || "").toLocaleUpperCase()
                }}</span>
              </template>
            </v-tooltip> -->

            <span v-if="gStore.isPc" :title="item.reviewer?.toLocaleUpperCase()" class="evaluater">{{
              (item.reviewer || "").toLocaleUpperCase()
            }}</span>
            <span v-else class="evaluater">{{
              (item.reviewer || "").toLocaleUpperCase()
            }}</span>
          </div>

          <div class="evaluate-text custom-scrollbar">
            {{ item.content }}
          </div>

          <div class="card-bottom">
            <v-rating
              :model-value="item.score"
              readonly
              :size="22"
              half-increments
              color="yellow-darken-3"
            ></v-rating>

            <span class="evaluate-time">{{ item.reviewDate }}</span>
          </div>
        </v-card>
      </swiper-slide>
    </swiper>
    <div class="button-prev btn" @click.stop="prevEl" v-if="isOneScreen">
      <img :src="pre" />
    </div>
    <div class="button-next btn" @click.stop="nextEl" v-if="isOneScreen">
      <img :src="next" />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue"
import { Swiper, SwiperSlide } from "swiper/vue"
import pre from "@/assets/svg/pre.svg"
import next from "@/assets/svg/next.svg"
import "swiper/css"
import { useGlobalStore } from "~/stores"

const gStore = useGlobalStore()

const props = defineProps({
  dataList: {
    type: Array,
    default: () => []
  }
})

watch(
  () => props.dataList,
  (newData) => {
    if (newData && newData.length) {
      groupDataList()
    }
  }
)

watch(
  () => gStore.isPc,
  (v) => {
    setslidesPerViewFn(v ? 6 : 3)
    groupDataList()
  }
)

let slidesPerView = ref(6) // 每屏显示的滑块数量

const isOneScreen = ref(true) // 是否是一屏

let useSwiper = ref(null) // swiper 实例

const groupedData = ref([]) // 转换后的二维数组

// 设置当前显示的评价个数
const setslidesPerViewFn = (num) => {
  slidesPerView.value = num
  if (props?.dataList?.length <= num) isOneScreen.value = false
}

// 处理获取到的数据转为二维数组
const groupDataList = () => {
  const dataList = props.dataList
  const perPage = slidesPerView.value
  const newGroupedData = []
  for (let i = 0; i < dataList.length; i += perPage) {
    newGroupedData.push(dataList.slice(i, i + perPage))
  }
  groupedData.value = newGroupedData
}

// 初始化swiper
const onSwiper = (swiper) => {
  useSwiper.value = swiper
  groupDataList()
}

// 上一下
const prevEl = () => {
  useSwiper.value && useSwiper.value.slidePrev()
}

// 下一页
const nextEl = () => {
  useSwiper.value && useSwiper.value.slideNext()
}

onMounted(() => {
  setslidesPerViewFn(gStore.isPc ? 6 : 3)
  groupDataList()
})
</script>
<style scoped lang="scss">
.project-swiper {
  position: relative;
  .swiper {
    width: 1040px;
  }
  .swiper-slide {
    width: 100% !important; /* 确保滑块宽度适配屏幕 */
    display: grid;
    grid-template-columns: repeat(3, 340px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    :deep(.swiper-slide-item) {
      padding: 24px 0 24px 24px;
      width: 100%;
      height: 250px !important;
      box-sizing: border-box;
      box-shadow: unset;
      border-radius: 0;
      .card-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        padding-right: 24px;
        .quotationMark {
          width: 48px;
          height: 42px;
          margin-left: -8px;
          background-image: url(@/assets/imgs/quotation-mark-primary.svg);
        }
        .evaluater {
          height: 24px;
          max-width: calc(100% - 54px);
          font-size: 18px;
          font-weight: 600;
          text-decoration: underline;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      .evaluate-text {
        height: calc(100% - 58px - 32px - 7px);
        padding: 0px 10px 0 0;
        margin-right: 6px;
        overflow: auto;
        line-height: 21px;
        font-size: 16px;
        color: #000000e6;
      }
      .card-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        padding-right: 24px;
        .evaluate-time {
          margin-top: 4px;
          line-height: 21px;
          color: #333;
          font-size: 18px;
          font-weight: 600;
          padding-top: 3px;
        }
      }
      &:hover {
        color: #fff;
        background-color: #00bbef !important;
        .quotationMark {
          background-image: url(@/assets/imgs/quotation-mark-white.svg);
        }
        .evaluater {
          color: #fff;
        }
        .v-rating__item {
          .text-yellow-darken-3 {
            color: #fff !important;
          }
        }
        .evaluate-text {
          color: #fff;
        }
        .evaluate-time {
          color: #fff;
        }
      }
    }
  }
  .btn {
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    opacity: 0.4;
    cursor: pointer;
    border-radius: 5px;
    line-height: 50px;
    text-align: center;
    margin-top: -25px;
    z-index: 9;
    &:hover {
      opacity: 1;
    }
  }
  .button-prev {
    left: -100px;
  }
  .button-next {
    right: -100px;
  }
}

.mobile-terminal {
  .swiper {
    width: 100%;
  }
  .swiper-slide {
    /* display: block; */
    overflow: hidden;
    grid-template-columns: repeat(1, 100%);
    row-gap: 8px;
    justify-content: flex-start;
    :deep(.swiper-slide-item) {
      padding: 12px;
      height: unset !important;
      .card-top {
        margin-bottom: 12px;
        .quotationMark {
          width: 20px;
          height: 18px;
          margin-left: -4px;
          background-image: url(@/assets/imgs/quotation-mark-primary.svg);
        }
        .evaluater {
          height: 18px;
          font-size: 12px;
        }
      }
      .evaluate-text {
        height: 57px;
        font-size: 13px;
        line-height: 14px;
        margin-right: 0px;
      }
      .card-bottom {
        margin-top: 8px;
        .v-rating__wrapper {
          width: 14px;
          .mdi-star,
          .mdi-star-outline {
            font-size: 14px !important;
          }
        }
        .evaluate-time {
          font-size: 13px;
          color: #0009;
        }
      }
      &:hover {
        color: #000000e6 !important;
        background-color: #ffffff !important;
        .quotationMark {
          background-image: url(@/assets/imgs/quotation-mark-primary.svg);
        }
        .evaluater {
          color: #000;
        }
        .v-rating__item {
          .text-yellow-darken-3 {
            color: #f9a825 !important;
          }
        }
        .evaluate-text {
          color: #000000e6 !important;
        }
        .evaluate-time {
          color: #0009 !important;
        }
      }
    }
  }
  .btn {
    width: 24px !important;
    height: 24px !important;
    &:hover {
      opacity: 0.4 !important;
    }
  }
  .button-prev {
    left: -30px !important;
  }
  .button-next {
    right: -30px !important;
  }
}
</style>
<!-- <style>
.evaluaterTooltip {
  color: #3c3c3c !important;
  /* border: 1px solid #3c3c3c !important; */
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255,.3) !important;
  padding: 2px 10px !important;
  font-size: 12px !important;
  border: none !important;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  transform: translateY(12px);
}
</style> -->