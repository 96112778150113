<template>
  <div class="container d-flex flex-column">
    <!-- 新版本 -->
    <template v-if="gStore.isNewVersion">
      <page-header-new />
      <div class="flex-1-0">
        <slot></slot>
      </div>
      <page-footer-new v-if="!productFooterHidden" />
    </template>

    <!-- 旧版本 -->
    <template v-else>
      <page-header />
      <div class="flex-1-0">
        <slot></slot>
      </div>
      <page-footer v-if="!productFooterHidden" />
    </template>
  </div>
</template>

<script setup>
import { useGlobalStore } from "~/stores";
import { useDisplay } from "vuetify";
const { name } = useDisplay();
const { $Utils } = useNuxtApp();
const route = useRoute();
const gStore = useGlobalStore()

const productFooterHidden = computed(() => {
  if (gStore.isNewVersion) {
    return (route.path.includes("product") && !gStore.isPc)
  } else {
    const isPc = $Utils.browserRedirectByPixel(name.value);
    return (route.path.includes("product") && !isPc)
  }
})
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}
</style>
