import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import create_45client_45id_45global from "/app/middleware/create-client-id.global.js";
import device_45type_45global from "/app/middleware/device-type.global.js";
export const globalMiddleware = [
  validate,
  create_45client_45id_45global,
  device_45type_45global
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.js"),
  "search-page-token-check": () => import("/app/middleware/search-page-token-check.js")
}