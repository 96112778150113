<template>
  <div class="shaopmall-skeleton" :style="style"></div>
</template>

<script setup>
const props = defineProps({
  width: {
    type: String,
    default: "100%"
  },
  height: {
    type: String,
    default: "100%"
  }
})

const style = computed(() => {
  return {
    width: props.width,
    height: props.height
  }
})
</script>

<style lang="scss" scoped>
.shaopmall-skeleton {
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.15);
  // background: linear-gradient(-45deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  // background-size: 400% 100%;
  // animation: el-skeleton-loading 1.4s ease infinite;
}

@keyframes el-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
