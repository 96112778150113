export const useSearchStore = defineStore("search", {
  state() {
    return {
      searchResult: null
    };
  },
  actions: {
    setSearchResult(data) {
      this.searchResult = data;
    }
  }
});
