import { UAParser } from 'ua-parser-js'
export const getClientInfo = (ua) => {
  if (!ua) {
    return {}
  }

  let clientType = "1";
  const uaLower = ua.toLowerCase();
  if (/ipad|ipod/.test(uaLower)) {
    clientType = "0";
  } else if (/android|iphone/.test(uaLower)) {
    clientType = "0";
  } else {
    clientType = "1";
  }

  const parser = new UAParser(ua);
  const parserResults = parser.getResult();

  return {
    browser: parserResults.browser.name,
    browserVersion: parserResults.browser.version,
    clientSystem: parserResults.os.name + ' ' + parserResults.os.version,
    clientType
  }
}