import {
  materialPage,
  printingWay,
  productDetails,
  categorys,
  productPage,
  imageConvert,
  alculateProductPrice,
  alculateProductPriceAndMatchDiscount,
  alculateProductDelivery,
  cartAdd,
  getIdByCode,
  productRelated,
  listBestSellers,
  listNewArrival,
  getProductDetailByCode,
  listRecommend,
  getIrregularFlag,
  orderProductSnapshot,
  reportSearch,
  productBrands,
  featuredTags,
  recommendProduct,
  HVProducts,
  brandProducts,
  newArrivals,
  featuredProducts,
  getProductFlierDetailByCode,
  getProductCatalog,
  getThemeInfo,
  themeProductPage
} from "../services/api/product";

class productInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new productInteractor();

  /**
   * 分页材质
   * @param {categoryId} 类目id
   */
  materialPage(id) {
    return materialPage({
      categoryId: id
    });
  }

  /**
   * 印刷方式
   */
  printingWay() {
    return printingWay();
  }

  /**
   * 产品详情
   * @param {id} 产品id
   */
  productDetails(id) {
    return productDetails(id);
  }

  /**
   * 获取类目列表
   */
  categorysApi() {
    return categorys();
  }

  /**
   * 分页获取产品列表
   * @param {materials} 材质
   * @param {printingWayIds} 印刷方式id
   * @param {priceMin} 产品价格Min
   * @param {priceMax} 产品价格Max
   * @param {quantity} 购买数量
   */
  productPage(params) {
    return productPage(params);
  }

  themeProductPageApi(params) {
    return themeProductPage(params);
  }

  /**
   * 图片格式转化
   */
  imageConvertApi(data) {
    return imageConvert(data);
  }

  /**
   * 计算产品价格
   */
  alculateProductPriceApi(params) {
    return alculateProductPrice(params);
  }

  alculateProductPriceAndMatchDiscountApi(params) {
    return alculateProductPriceAndMatchDiscount(params);
  }
  /**
   * 计算产品预计到达时间
   */
  alculateProductDeliveryApi(params) {
    return alculateProductDelivery(params);
  }

  /**
   * 添加购物车
   */
  cartAddApi(params) {
    return cartAdd(params);
  }

  /**
   * 获取产品ID
   * @param {code} 产品code
   */
  getIdByCodeApi(code) {
    return getIdByCode({ code: code });
  }

  /**
   * 相关产品
   * @param {id}
   */
  productRelatedApi(id) {
    return productRelated(id);
  }

  //获取销量好的产品列表
  async listBestSellersApi() {
    let res = await listBestSellers();
    return res;
  }

  // 获取新品
  async listNewArrivalApi() {
    let res = await listNewArrival();
    return res;
  }

  async getProductDetailByCodeApi(code) {
    let res = await getProductDetailByCode(code);
    return res;
  }
  async listRecommendApi() {
    let res = await listRecommend();
    return res;
  }

  /**
   * 获取产品不规则标记
   * @param {id} 产品id
   */
  getIrregularFlagApi(id) {
    return getIrregularFlag(id);
  }

  /**
   * 产品详情
   * @param {id} 产品id
   */
  orderProductSnapshot(data) {
    let { orderProductId } = data;
    return orderProductSnapshot({ orderProductId });
  }

  // 搜索辅助谷歌埋点上报日志记录
  reportSearchApi(query) {
    const { keyWord, type, source, id, method, version = "" } = query;
    return reportSearch({
      keyWord, // 搜索关键字
      type, // 1:搜索  2:搜索成功 3:搜索有结果
      source, // 1:搜索点击 2:复制连接
      id, // 一对请求回调的id一样
      method, // request  或  callback
      version // 版本号
    });
  }

  // 首页获取brands
  productBrandsApi() {
    return productBrands()
  }

  // 首页获取Featured Products的tag
  featuredTagsApi() {
    return featuredTags()
  }

  // 首页获取Featured Products
  featuredProductsApi(data) {
    return featuredProducts(data)
  }

  // 首页获取类目
  recommendProductApi(categoryId) {
    return recommendProduct(categoryId)
  }
  /**
   * 获取品牌产品列表
   */
  brandProductsApi(data) {
    let { brandId, current, orders, priceMax, priceMin, quantity, size } = data;
    return brandProducts({
      brandId,
      current,
      orders,
      priceMax,
      priceMin,
      quantity,
      size
    });
  }

  /**
   * 商城首页-New Arrival
   * priceMax 产品价格Max
   * priceMin 产品价格Min
   * quantity 购买数量
   */
  newArrivalsApi(data) {
    let { priceMax, priceMin, quantity } = data;
    return newArrivals({
      priceMax,
      priceMin,
      quantity
    });
  }

  /**
   * 商城首页-Featured Products
   * queryType 查询类型：1-24H Rush，2-Free Shipping，3-Free Sample
   */
  featuredProductsApi(data) {
    let { current, orders, priceMax, priceMin, quantity, size, queryType } =
      data;
    return featuredProducts({
      current,
      orders,
      priceMax,
      priceMin,
      quantity,
      size,
      queryType
    });
  }

  // 获取High-Value产品
  HVProductsApi(){
    return HVProducts()
  }
  // 宣传单页获取产品详情
  getProductFlierDetailByCodeApi(code){
    return getProductFlierDetailByCode(code);
  }

  // 获取产品目录配置
  getProductCatalogApi(){
    return getProductCatalog();
  }
  
  // 专题活动信息
  getThemeInfoApi(id) {
    return getThemeInfo(id);
  }
}

export default productInteractor.getInstance();
