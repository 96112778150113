import wewoo from "@wewoo/error";
export default defineNuxtPlugin(nuxtApp => {
  const env = import.meta.env.VITE_NODE_ENV;
  if (['prod', 'pre'].includes(env)) {
    let userValue = localStorage.getItem("user");
    userValue = userValue ? JSON.parse(userValue) : {};
    const userId = userValue?.userInfo?.userId ?? "1716332637171220480";
    // 因商城没有uat环境，所以把商城的pre环境当作uat环境，再利用erp的uat接口作为上报地址
    const dsn = env === 'pre' ? 'https://beta.wewoerp.com' : window.location.origin;
    nuxtApp.vueApp.use(wewoo, {
      dsn: dsn + "/wewoo-log-center/monitor/reportErrorData",
      sysCode: "SHOPMALL",
      appCode: 10003,
      repeatCodeError: false,
      useImgUpload: false,
      userId,
      handleHttpStatus() {
        return true;
      }
    });
  }
});
