import { productInteractor } from "~/core";
import { randomHashId } from '~/public/js/utils';

import { searchKeywordClick, searchKeywordClickSuccess, searchKeywordClickResult } from "./buryingpoint";

let searchSourceType = 1
const SearchClickReport = (keyword) => {
  // 获取搜索行为标示存起来 然后删除本地存储 来区别用户是通过点击搜索框还是复制或刷新页面来访问触发
  searchSourceType = localStorage.getItem("searchSourceClick") ? 1 : 2
  localStorage.removeItem("searchSourceClick")

  const reportQuery = {
    keyWord: keyword,
    type: 1,
    source: searchSourceType,
    id: randomHashId(),
    version: localStorage.getItem("searchVersion") || ''
  }
  productInteractor.reportSearchApi({...reportQuery, method: 'request'})

  searchKeywordClick(keyword, () => {
    productInteractor.reportSearchApi({...reportQuery, method: 'callback'})
  });
}

const SearchSuccessReport = (keyword) => {
  const reportQuery = {
    keyWord: keyword,
    type: 2,
    source: searchSourceType,
    id: randomHashId(),
    version: localStorage.getItem("searchVersion") || ''
  }
  productInteractor.reportSearchApi({...reportQuery, method: 'request'})
  searchKeywordClickSuccess(keyword, () => {
    productInteractor.reportSearchApi({...reportQuery, method: 'callback'})
  });
}

const SearchResultReport = (keyword) => {
  const reportQuery = {
    keyWord: keyword,
    type: 3,
    source: searchSourceType,
    id: randomHashId(),
    version: localStorage.getItem("searchVersion") || ''
  }
  productInteractor.reportSearchApi({...reportQuery, method: 'request'})
  searchKeywordClickResult(keyword, () => {
    productInteractor.reportSearchApi({...reportQuery, method: 'callback'})
  });
}

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      SearchClickReport,
      SearchSuccessReport,
      SearchResultReport
    }
  };
});
