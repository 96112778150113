export function useLocalCachedApi(key, p, params) {
  return new Promise((resolve, reject) => {
    const localData = localStorage.getItem(key)
    if (localData) {
      resolve(JSON.parse(localData))
    }
    p(params)
      .then((response) => {
        const [res, err] = response
        if (!err) {
          localStorage.setItem(key, JSON.stringify(res))
          resolve(res)
        } else {
          localStorage.removeItem(key)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
