import { judgeDevice } from "kits";
import { getBrowserInfo } from "./utils.js";
import { useGlobalStore } from "~/stores"
let client_type; // 设备类型

let pointEvents = [];
function mallGtag(eventType, option, event_callback) {
  try {
    if (window?.gtag) {
      let user = localStorage.getItem("user");
      const search_version = localStorage.getItem("searchVersion");
      let loginName = "";
      if (user) {
        loginName = JSON.parse(user)?.userInfo?.loginName;
      }
      if (window) {
        client_type = client_type || judgeDevice();
      }

      // 上报成功后的回调函数
      const callbackFun = typeof event_callback === "function" ? { event_callback } : {};

      window.gtag("event", eventType, {
        ...option,
        ...callbackFun,
        user_name: loginName || "",
        client_type: client_type === "pc" ? "1" : "0",
        is_logged_in: loginName ? "1" : "0",
        search_version
      });
    } else {
      pointEvents.push({ eventType, option });
    }
  } catch (e) {}
}

export function triggerPointEvents() {
  if (pointEvents && pointEvents.length > 0) {
    pointEvents.forEach(item => {
      mallGtag(item.eventType, item.option);
    });
    pointEvents = [];
  }
}

const BuryingPointTypes = {
  addCartFromSearch: "/SEARCH/ADDCART", //搜索加购
  addCartFromBanner: "/BANNER/ADDCART", // BANNER点击加购
  addCartFromBest: "/BEST/ADDCART", //畅销点击加购
  addCartFromNew: "/NEW/ADDCART", // 新品点击加购
  addCartFromThemeActivity: "/THEMEACTIVITY/ADDCART", // 专题页点击加购
  addCartFromBannerThemeActivity: "/BANNER/THEMEACTIVITY/ADDCART", // banner专题页点击加购
  addCartFromHistory:'/HISTORY/ADDCART',  //浏览历史加购
  addCartFromCategory:'/CATEGORY/ADDCART',  //分类加购
  addOrderFromSearch: "/SEARCH/ADDORDER", //搜索下单
  addOrderFromBanner: "/BANNER/ADDORDER", //banner点击下单
  addOrderFromBest: "/BEST/ADDORDER", //畅销点击下单
  addOrderFromNew: "/NEW/ADDORDER" //新品点击下单
};

const eventTypes = {
  search_keyword_click: "search_keyword_click", //关键词搜索
  search_keyword_success: "search_keyword_success", //关键词搜索成功
  search_keyword_click_result: "search_keyword_click_result", //搜索有结果
  search_keyword_product_click: "search_keyword_product_click", // 搜索产品点击
  search_keyword_add_cart: "search_keyword_add_cart", // 搜索进入产品详情添加购物车
  search_keyword_add_order: "search_keyword_add_order", //搜索产品下单
  banner_click: "banner_click", //首页banner点击
  banner_product_click: "banner_product_click", // banner产品点击
  banner_product_add_cart: "banner_product_add_cart", // banner加购
  banner_product_add_order: "banner_product_add_order", // banner下单

  best_product_click: "best_product_click", // best产品点击
  best_product_add_cart: "best_product_add_cart", // best加购
  best_product_add_order: "best_product_add_order", // best下单

  new_product_click: "new_product_click", // newArrival产品点击
  new_product_add_cart: "new_product_add_cart", // newArrival加购
  new_product_add_order: "new_product_add_order", // newArrival下单

  theme_activity_add_cart: "theme_activity_add_cart", // 专题页加购
  theme_activity_add_order: "theme_activity_add_order", // 专题页下单
  banner_theme_activity_add_cart: "banner_theme_activity_add_cart", // banner专题页加购
  banner_theme_activity_add_order: "banner_theme_activity_add_order", // banner专题页下单
  theme_acivity_product_click: 'theme_acivity_product_click', // 活动页进入详情页

  banner_exposure: "banner_exposure", //首页banner曝光
  new_arrival_exposure: "new_arrival_exposure", // newArrival曝光
  best_sellers_exposure: "best_sellers_exposure", // best曝光
  search_exposure: "search_exposure", //搜索曝光
  category_exposure: "category_exposure", //分类曝光
  history_product_add_cart:'history_product_add_cart', // 浏览记录产品加购
  history_product_add_order:'history_product_add_order', // 浏览记录产品下单
  category_product_add_cart:'category_product_add_cart',  //分类加购
  category_product_add_order:'category_product_add_order',  //分类下单
  evaluate_exposure:'evaluate_exposure',  //客户评价曝光
  top_categories_exposure:'top_categories_exposure',  // 热门分类曝光
  order_tracking_exposure:'order_tracking_exposure',  // 订单跟踪曝光
  history_product_click:'history_product_click',  // 浏览记录产品点击
  home_category_click:'home_category_click', //首页分类点击
  category_product_click:'category_product_click', //分类产品点击
  home_product_click:'home_product_click', //首页进入详情页
  category_click:'category_click', //分类点击
  new_version_pv: 'new_version_pv', // 新版本
  old_version_pv: 'old_version_pv', // 老版本

  exposure_home_banner: "exposure_home_banner", //首页banner曝光
  exposure_home_top_selling: "exposure_home_top_selling", //首页top selling曝光
  exposure_home_hv_products_top: "exposure_home_hv_products_top", //首页热销产品曝光
  exposure_home_hv_products_bottom: "exposure_home_hv_products_bottom", //首页热销产品曝光
  exposure_home_print_image: "exposure_home_print_image", //首页印刷品曝光
  exposure_home_comment: "exposure_home_comment", //首页评论曝光

  home_to_category: "home_to_category_list", // 首页到分类列表
  home_to_detail: "home_to_detail", // 首页到详情页
};

//关键词搜索埋点
export function searchKeywordClick(keyword, callback) {
  mallGtag(eventTypes.search_keyword_click, {
    search_keyword: keyword
  }, callback);
}

// 关键词搜索成功埋点
export function searchKeywordClickSuccess(keyword, callback) {
  mallGtag(eventTypes.search_keyword_success, {
    search_keyword: keyword
  }, callback);
}
// 关键词搜索成功且有数据埋点
export function searchKeywordClickResult(keyword, callback) {
  mallGtag(eventTypes.search_keyword_click_result, {
    search_keyword: keyword
  }, callback);
}

// 搜索产品点击
function searchKeywordProductClick(option = {}) {
  const { search_keyword, product_name } = option;
  mallGtag(eventTypes.search_keyword_product_click, {
    search_keyword,
    product_name
  });
}

// 添加购物车埋点
function addCart(query, product) {
  const { t, k, businessid } = query;
  if (t === "s") {
    // 搜索加入购物车
    // 执行埋点
    mallGtag(eventTypes.search_keyword_add_cart, {
      product_name: product?.nameEn,
      search_keyword: query.k
    });
    // 返回参数
    return `${BuryingPointTypes.addCartFromSearch}/${query.k}`;
  } else if (t === "ba") {
    //banner加入购物车
    mallGtag(eventTypes.banner_product_add_cart, {
      product_name: product?.nameEn
    });
    return `${BuryingPointTypes.addCartFromBanner}`;
  } else if (t === "be") {
    // best sellers产品加购
    mallGtag(eventTypes.best_product_add_cart, {
      product_name: product?.nameEn
    });
    return `${BuryingPointTypes.addCartFromBest}`;
  } else if (t === "ne") {
    // new Arrivals产品加购
    mallGtag(eventTypes.new_product_add_cart, {
      product_name: product?.nameEn
    });
    return `${BuryingPointTypes.addCartFromNew}`;
  } else if (t === "ta") {
    // 专题页加购
    mallGtag(eventTypes.theme_activity_add_cart, {
      product_name: product?.nameEn,
      theme_activity_id: businessid
    });
    return `${BuryingPointTypes.addCartFromThemeActivity}`;
  } else if (t === "ba_ta") {
    mallGtag(eventTypes.banner_product_add_cart, {
      product_name: product?.nameEn,
      theme_activity_id: businessid
    });
    // 专题页加购
    mallGtag(eventTypes.banner_theme_activity_add_cart, {
      product_name: product?.nameEn,
      theme_activity_id: businessid
    });
    return [`${BuryingPointTypes.addCartFromBanner}`, `${BuryingPointTypes.addCartFromBannerThemeActivity}`]
  } else if (t === "history") {
    // 浏览记录加购
    mallGtag(eventTypes.history_product_add_cart, {
      product_name: product?.nameEn,
    });
    return `${BuryingPointTypes.addCartFromHistory}`;
  } else if (t === "ca") {
    // 分类加购
    mallGtag(eventTypes.category_product_add_cart, {
      product_name: product?.nameEn,
    });
    return `${BuryingPointTypes.addCartFromCategory}/${product?.category?.parent?.nameEn || product?.category?.nameEn}`;
  } else if (t === "ba_ca") {
    mallGtag(eventTypes.banner_product_add_cart, {
      product_name: product?.nameEn,
      theme_activity_id: businessid
    });
    // 分类加购
    mallGtag(eventTypes.category_product_add_cart, {
      product_name: product?.nameEn,
    });
    return [`${BuryingPointTypes.addCartFromBanner}`, `${BuryingPointTypes.addCartFromCategory}/${product?.category?.parent?.nameEn || product?.category?.nameEn}`]
  }
  
  return "";
}

// 下单埋点
function addOrder(cartList, orderDetail, query) {
  for (let i = 0, len = cartList.length; i < len; i++) {
    const product = cartList[i];
    const optSources = product?.optSource ? [{optSource: product?.optSource}] : (product?.optSources || [])
    
    for (let j = 0; j < optSources.length; j++) {
      const source = optSources[j].optSource;
      
      let myCase = "";
      if (source.includes(BuryingPointTypes.addCartFromSearch)) {
        myCase = BuryingPointTypes.addCartFromSearch;
      } else if (source.includes(BuryingPointTypes.addCartFromCategory)) {
        myCase = BuryingPointTypes.addCartFromCategory;
      }
      switch (myCase ? myCase : source) {
        // 搜索下单
        case BuryingPointTypes.addCartFromSearch:
          const paths = source.split("/");
          const searchKeyword = paths[paths.length - 1];
          mallGtag(eventTypes.search_keyword_add_order, {
            produt_name: product?.productInfo?.nameEn,
            order_code: orderDetail?.code,
            search_keyword: searchKeyword
          });
          break;
        // banner下单
        case BuryingPointTypes.addCartFromBanner:
          mallGtag(eventTypes.banner_product_add_order, {
            produt_name: product?.productInfo?.nameEn,
            order_code: orderDetail?.code
          });
          break;
        // best下单
        case BuryingPointTypes.addCartFromBest:
          mallGtag(eventTypes.best_product_add_order, {
            produt_name: product?.productInfo?.nameEn,
            order_code: orderDetail?.code
          });
          break;
        // 新品下单
        case BuryingPointTypes.addCartFromNew:
          mallGtag(eventTypes.new_product_add_order, {
            produt_name: product?.productInfo?.nameEn,
            order_code: orderDetail?.code
          });
          break;
        // 专题页下单
        case BuryingPointTypes.addCartFromThemeActivity:
          mallGtag(eventTypes.theme_activity_add_order, {
            produt_name: product?.productInfo?.nameEn,
            order_code: orderDetail?.code,
            theme_activity_id: query.businessid
          });
          break;
        // Banner专题页下单
        case BuryingPointTypes.addCartFromBannerThemeActivity:
          mallGtag(eventTypes.banner_theme_activity_add_order, {
            produt_name: product?.productInfo?.nameEn,
            order_code: orderDetail?.code,
            theme_activity_id: query.businessid
          });
          break
        // 浏览记录下单
        case BuryingPointTypes.addCartFromHistory:
          mallGtag(eventTypes.history_product_add_order, {
            produt_name: product?.productInfo?.nameEn,
            order_code: orderDetail?.code
          });
          break
        // 分类下单
        case BuryingPointTypes.addCartFromCategory:
          mallGtag(eventTypes.category_product_add_order, {
            produt_name: product?.productInfo?.nameEn,
            order_code: orderDetail?.code
          });
          break
      }
    }
  }
}


// page_view事件埋点
export function pageView() {
  mallGtag("page_view", {
    page_path: window?.location?.pathname,
    page_title: window?.document?.title,
    page_location: window?.location?.href
  });
}
// 设置内容组
const SetContentGroups = function (groupName) {
  mallGtag("page_view", {
    Content_Group: groupName
  });
};

// 首页banner点击埋点
function bannerClick(productName) {
  mallGtag(eventTypes.banner_click, {
    produt_name: productName
  });
}

function bannerProductClick(url) {
  mallGtag(eventTypes.banner_product_click, {
    click_src: url
  });
}

function bestClick(product) {
  mallGtag(eventTypes.best_product_click, {
    produt_name: product?.nameEn
  });
}

function newClick(product) {
  mallGtag(eventTypes.new_product_click, {
    produt_name: product?.nameEn
  });
}

// 通用点击事件埋点
function commonClick(eventType,options) {
  mallGtag(eventTypes[eventType], options);
}

const MallPageName = {
  mallProductDetail: "mall_product_detail", // 商品详情
  mallMyOrder: "mall_my_order", // 我的订单
  mallBrowsingHistory: "mall_browsing_history", // 浏览记录
  mallContactus: "mall_contactus", // 联系我们
  mallMyCart: "mall_my_cart", // 购物车
  mallOrderDetail: "mall_order_detail", // 订单详情
  mallCheckOut: "mall_check_out", // checkout
  mallHeader: "mall_header", // header,
  mallProductCatalog: "mall_product_catalog", // 分类目录
  mallFlyer:'mall_flyer' //宣传单
};

const ClickSource = {
  mallProductDetailDownloadTemplate: "mall_product_detail_download_template", // 下载模板按钮
  mallProductDetailPrintLoction: "mall_product_detail_print_loction", // 印刷位置
  mallProductDetailPrintStiches: "mall_product_detail_print_stiches", // 印刷针数
  mallProductDetailUploadDesign: "mall_product_detail_upload_design", // 上传设计
  mallProductDetailResidentialAddress:
    "mall_product_detail_residential_address", // 居住地址
  mallProductDetailSendSample: "mall_product_detail_send_sample", // 发送实物样按钮
  mallMyOrderSearch: "mall_my_order_search", // 订单搜索/搜索按钮
  mallMyOrderStatus: "mall_my_order_status", // 订单搜索/状态
  mallMyOrderViewOrder: "mall_my_order_view_order", // 订单列表/查看详情
  mallMyOrderPrintInvoice: "mall_my_order_print_invoice", // 订单列表/查看发票
  mallMyOrderViewProductImg: "mall_my_order_view_product_img", // 订单列表/查看商品图
  mallBrowsingHistoryEdit: "mall_browsing_history_edit", // 编辑浏览记录
  mallBrowsingGoShopping: "mall_browsing_go_shopping", // 去购物
  mallContactusService: "mall_contactus_service", // 服务类型下拉
  mallContactusLeaveAMessage: "mall_contactus_leave_a_message", // 留言按钮
  mallContactusBusinessCollaborationEmail:
    "mall_contactus_business_collaboration_email", // 业务合作邮箱
  mallContactusBusinessAftersalesEmail:
    "mall_contactus_business_aftersales_email", // 售后服务邮箱
  mallMyCartRemove: "mall_my_cart_remove", // 移除商品
  mallMyCartEditQty: "mall_my_cart_edit_qty", // 编辑数量
  mallMyCartContinueShopping: "mall_my_cart_continue_shopping", // 继续购物
  mallOrderDetailCopyOrderNo: "mall_order_detail_copy_order_no", // 复制订单号
  mallOrderDetailContactUs: "mall_order_detail_contact_us", // 联系我们
  mallRegisterSuccess: "mall_register_success", // 注册成功事件
  mallProductDetailAddCartSuccess: "mall_product_detail_add_cart_success", //加购事件
  mallProductDetailEnter: "mall_product_detail_enter", //进入产品详情事件
  mallCheckoutSuccess: "mall_checkout_success", //下单成功事件
  mallHeaderSearch: "mall_header_search_btn",

  mallShareProduct:'mall_share_product', // 商品详情分享产品
  mallShareProductSuccess:'mall_share_product_success', //商品详情分享产品成功
  mallShareProductClose:'mall_share_product_close', //商品详情分享产品关闭
  mallDownloadFlyer:'mall_download_flyer', //下载传单
  mallFlyerImportMethodQuantity:'mall_flyer_import_method_quantity', // 下载传单页印刷方式按钮
  mallFlyerProcudtDescripiton:'mall_flyer_procudt_descripiton', // 下载传单页描述按钮
  mallFlyerProcudtSpecifications:'mall_flyer_procudt_specifications', // 下载传单页规格按钮
  mallFlyerDownloadFlyer:'mall_flyer_download_flyer', // 下载传单页下载按钮
  mallProductCatalog:'mall_product_catalog', //产品分类目录
  mallDownloadHiResImage:'mall_download_hi-res_image', //下载高清大图
  mallPreviewBigImage:'mall_preview_big_iamge',  //查看大图
  mallHome:'mall_home',  //首页
  mallHomeBanner:'mall_home_banner',  //首页banner
};

function BaseClickBurying(mallPageName, clickSource) {
  mallGtag("btn_click", {
    mall_page_name: mallPageName,
    click_source: clickSource
  });
}

class BuryIntersectionObserver {
  constructor(className, option) {
    // 检测是否支持IntersectionObserver
    if (typeof IntersectionObserver === "undefined") {
      const browserInfo = getBrowserInfo();
      throw new Error(`您的浏览器不支持IntersectionObserver: ${browserInfo}`);
    }
    this.elementData = new WeakMap(); //存储已上报的元素，避免重复上报
    this.option = option || {}; // 配置项

    this.className = className;
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (!this.elementData.get(entry.target)) {
              this.elementData.set(entry.target, 1);
              // 开始埋点
              this.sendExposureData(entry.target);
            }
          }
        });
      },
      { threshold: 0, ...option }
    );

    this.initElements();
  }

  // 获取DOM元素
  initElements() {
    let className = this.className;
    let elements = [];
    if (typeof className === "string") {
      elements = document.querySelectorAll(className);
    } else if (Array.isArray(className)) {
      className.forEach(name => {
        elements.push(...document.querySelectorAll(name));
      });
    }
    elements.forEach(element => {
      this.observer.observe(element);
    });
  }

  // 具体埋点
  sendExposureData(target) {
    const gStore = useGlobalStore()
    let classList = [...target.classList];
    let eventType = "";
    if (classList.includes("v-window-item")) {
      eventType = gStore.isNewVersion ? eventTypes.exposure_home_banner : eventTypes.banner_exposure;
    } else if(classList.includes("evaluate-wrapper")) {
      eventType = eventTypes.evaluate_exposure;
    } else if(classList.includes("top-categories-module")) {
      eventType = eventTypes.top_categories_exposure;
    } else if(classList.includes("order-tracking-box")) {
      eventType = eventTypes.order_tracking_exposure;
    } else if(classList.includes("exposure_home_top_selling")) {
      eventType = eventTypes.exposure_home_top_selling;
    } else if(classList.includes("exposure_home_hv_products_top")) {
      eventType = eventTypes.exposure_home_hv_products_top;
    } else if(classList.includes("exposure_home_hv_products_bottom")) {
      eventType = eventTypes.exposure_home_hv_products_bottom;
    } else if(classList.includes("exposure_home_print_image")) {
      eventType = eventTypes.exposure_home_print_image;
    } else if(classList.includes("exposure_home_comment")) {
      eventType = eventTypes.exposure_home_comment;
    } else {
      // 获取dataset
      const dataset = target.dataset;
      if (dataset.best === "true") {
        eventType = eventTypes.best_sellers_exposure;
      } else if (dataset.new === "true") {
        eventType = eventTypes.new_arrival_exposure;
      } else if (dataset.name === "category-id") {
        eventType = eventTypes.category_exposure;
      } else if (dataset.name === "search") {
        eventType = eventTypes.search_exposure;
      }
    }
    
    if (eventType) {
      mallGtag(eventType);
    }
  }
}

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      Buryingpoint: {
        //复杂埋点
        addCart,
        searchKeywordClick,
        searchKeywordClickSuccess,
        searchKeywordClickResult,
        searchKeywordProductClick,
        addOrder,
        bannerClick,
        bannerProductClick,
        bestClick,
        newClick,
        commonClick
      },
      BuryingPointTypes, // 埋点类型
      BaseClickBurying, // 基础点击事件埋点
      MallPageName, //事件所在页面名称
      ClickSource, // 事件源
      BuryIntersectionObserver,
      SetContentGroups
    }
  };
});
