import { judgeDevice } from "kits";
import { triggerPointEvents } from "./buryingpoint";

/**
 * @ description 获取默认配置
 * @returns
 */
function getDefaultConfig() {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : {};
  return {
    user_name: user?.userInfo?.email ?? "",
    is_logged_in: user?.userInfo?.email ? "1" : "0",
    client_type: judgeDevice() === "pc" ? "1" : "0"
  };
}

/**
 * @description 初始化gtag
 * @param {*} gtagId
 */
function initGtag(gtagId) {
  function gtag() {
    window.dataLayer.push(arguments);
  }

  window.dataLayer = window.dataLayer || [];
  if (window) {
    window.gtag = gtag;
    window.__gtag_id__ = gtagId;
  }
  gtag("js", new Date());
  // 初始化配置参数
  const config = getDefaultConfig();
  gtag("config", gtagId, config);
}

/**
 * @description 更新gtag配置
 * @param {*} config
 */
export function updateGtagConfig(config) {
  if (window && window.gtag) {
    const defaultConfig = getDefaultConfig();
    window.gtag("config", window.__gtag_id__, { ...defaultConfig, ...config });
  }
}

function loadGTagScript() {
  const { gtagId, gtagTenantIds } = useRuntimeConfig().public;

  initGtag(gtagId);
  // 谷歌分析判断条件
  if (
    gtagTenantIds.includes(import.meta.env.VITE_TENANT_ID) &&
    import.meta.env.VITE_NODE_ENV === "prod" || true
  ) {
    var s = document.createElement("script");
    var h = document.querySelector("head") || document.body;
    s.setAttribute("id", "gtag-script");
    s.setAttribute(
      "src",
      `https://www.googletagmanager.com/gtag/js?id=${gtagId}&currentTimeStamp=${Date.now()}`
    );
    s.setAttribute("type", "text/javascript");
    s.setAttribute("async", true);
    s.onload = function () {
      console.log("GTM script loaded successfully");
      triggerPointEvents();
      const gtagLoaded = localStorage.getItem("gtagLoaded");
      if (gtagLoaded && JSON.parse(gtagLoaded)) {
        return;
      }
      localStorage.setItem("gtagLoaded", true);
      location.reload();
    };
    s.onerror = function (e) {
      console.log("Failed to load GTM script");
    };
    h.appendChild(s);
  }
}

// 清理 gtag
export function cleanupGTag() {
  localStorage.removeItem("gtagLoaded");
  window.dataLayer = [];
  delete window.gtag;
  const gtagScripts = document.querySelectorAll("#gtag-script");
  for (let i = gtagScripts.length - 1; i >= 0; i--) {
    const script = gtagScripts[i];
    script.parentNode.removeChild(script);
  }
}

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: {
      loadGTagScript,
      cleanupGTag
    }
  };
});
