<template>
  <div class="project-swiper" :class="{ 'mobile-terminal': !gStore.isPc }">
    <!-- 上半部分 -->
    <div class="top exposure_home_hv_products_top" v-if="groupedDataTop.length > 0">
      <swiper
        loop
        :modules="modules"
        :spaceBetween="0"
        :slidesPerView="1"
        :slidesPerGroup="1"
        :centeredSlides="false"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
        :pagination="pagination"
        :resistance="true"
        :resistanceRatio="0.85"
        @swiper="onSwiperTop"
      >
        <swiper-slide v-for="(item, i) in groupedDataTop" :key="i">
          <template v-if="gStore.isPc">
            <div
              class="swiper-slide-item"
            >
              <img :src="item.imageUrl" @click="jumpProduct(item, 'productNameEn')" />
              <div class="introduce" @click="jumpProduct(item, 'productNameEn')">
                <div class="title">{{ item.title }}</div>
                <div class="introduce-text">{{ item.introduce }}</div>
                <div class="more">MORE</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="swiper-slide-item"
              @click="jumpProduct(item, 'productNameEn')"
            >
              <div class="title">{{ item.title }}</div>
              <div class="introduce">
                <div class="left">
                  <div class="introduce-text">{{ item.introduce }}</div>
                  <div class="more">MORE</div>
                </div>
                <img :src="item.imageUrl" />
              </div>
            </div>
          </template>
        </swiper-slide>
      </swiper>
    </div>
    <!-- 下半部分 -->
    <div class="bottom exposure_home_hv_products_bottom" v-if="groupedDataBottom.length > 0">
      <swiper
        loop
        :modules="modules"
        :spaceBetween="0"
        :slidesPerView="1"
        :slidesPerGroup="1"
        :centeredSlides="false"
        :resistance="true"
        :resistanceRatio="0.85"
        @swiper="onSwiperBottom"
      >
        <swiper-slide v-for="(group, i) in groupedDataBottom" :key="i">
          <div
            class="swiper-slide-item"
            v-for="(item, j) in group"
            :key="j"
            @click="jumpProduct(item, 'nameEn')"
          >
            <div class="product-img">
              <img :src="item.imgMainUrl" />
            </div>
            <div class="paroduct-name">{{ item.nameEn }}</div>
            <div class="product-bottom" v-if="gStore.isPc">
              <div class="product-code"># {{ item.code }}</div>
              <img
                class="product-tag"
                :src="
                  item.calcFreight
                    ? freeShipping
                    : item.freeSampleFlag
                    ? freeSample
                    : ''
                "
              />
            </div>
            <template v-else>
              <div class="product-code"># {{ item.code }}</div>
              <img
                class="product-tag"
                :src="
                  item.calcFreight
                    ? freeShipping
                    : item.freeSampleFlag
                    ? freeSample
                    : ''
                "
              />
            </template>
          </div>
        </swiper-slide>
      </swiper>
      <template v-if="groupedDataBottom.length > 1">
        <div
          class="button-prev btn"
          @click.stop="prevEl"
          v-if="groupedDataBottom.length > 1"
        >
          <img :src="pre" />
        </div>
        <div
          class="button-next btn"
          @click.stop="nextEl"
          v-if="groupedDataBottom.length > 1"
        >
          <img :src="next" />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch, computed } from "vue"
import { useRouter } from "vue-router"
import { Swiper, SwiperSlide } from "swiper/vue"
import { Pagination, A11y, Autoplay } from "swiper/modules"
import { useGlobalStore } from "~/stores"
import freeSample from "@/assets/svg/freeSample.svg"
import freeShipping from "@/assets/svg/freeShipping.svg"
import { productInteractor } from "~/core"
import pre from "@/assets/svg/pre.svg"
import next from "@/assets/svg/next.svg"
const modules = [Pagination, A11y, Autoplay]
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/autoplay"

const router = useRouter()

const { $Utils, $BuryIntersectionObserver } = useNuxtApp()

const gStore = useGlobalStore()

watch(
  () => gStore.isPc,
  (v) => {
    slidesPerViewStep.value = v ? 3 : 2
    getBottomData()
  }
)

const pagination = computed(() => {
  return groupedDataTop.value.length > 1? {clickable: true} :null
})


const slidesPerViewStep = ref(3) // 每屏显示的滑块数量

const isOneScreen = ref(true) // 是否是一屏

const setslidesPerViewFn = (num, data) => {
  slidesPerViewStep.value = num
  if (data.length <= num) isOneScreen.value = false
}

// 处理获取到的数据转为二维数组
const groupDataList = (data) => {
  const perPage = slidesPerViewStep.value
  const newGroupedData = []
  for (let i = 0; i < data.length; i += perPage) {
    newGroupedData.push(data.slice(i, i + perPage))
  }
  groupedDataBottom.value = newGroupedData
}

// 初始化swiper
let useSwiperTop = ref(null) // swiper 实例
const onSwiperTop = (swiper) => {
  useSwiperTop.value = swiper
}
let useSwiperBottom = ref(null) // swiper 实例
const onSwiperBottom = (swiper) => {
  useSwiperBottom.value = swiper
}

// 上一下
const prevEl = () => {
  useSwiperBottom.value && useSwiperBottom.value.slidePrev()
}

// 下一页
const nextEl = () => {
  useSwiperBottom.value && useSwiperBottom.value.slideNext()
}

// 获取上半部分数据
const groupedDataTop = ref([])
const getTopData = async () => {
  const res = await useLocalCachedApi(
    "HVProducts",
    productInteractor.HVProductsApi
  )
  if (res) {
    groupedDataTop.value = res.map((item) => {
      return {
        ...item,
        imageUrl: useWebp(item.imageUrl)
      }
    })
  }

  nextTick(() => {
    new $BuryIntersectionObserver(".exposure_home_hv_products_top");
  })
}

// 获取下半部分数据
const groupedDataBottom = ref([])
const getBottomData = async () => {
  const res = await useLocalCachedApi(
    "bestSellers",
    productInteractor.listBestSellersApi
  )
  if (res) {
    groupedDataBottom.value = res.map((item) => {
      return {
        ...item,
        imgMainUrl: useWebp(item.imgMainUrl)
      }
    })
    setslidesPerViewFn(slidesPerViewStep.value, groupedDataBottom.value)
    groupDataList(groupedDataBottom.value)
  }
  nextTick(() => {
    new $BuryIntersectionObserver(".exposure_home_hv_products_bottom");
  })
}

// 跳转详情
const jumpProduct = (productInfo, name) => {
  router.push(
    `/product/${
      productInfo.code || productInfo.productCode
    }_${$Utils.replaceSpacesWithDash(productInfo[name])}?source=new_home`
  )
}

// 初始化时和窗口大小改变时更新
onMounted(() => {
  slidesPerViewStep.value = gStore.isPc ? 3 : 2
  getTopData()
  getBottomData()
})
</script>
<style scoped lang="scss">
.project-swiper {
  padding-top: 40px;
  :deep(.top) {
    position: relative;
    .swiper-wrapper {
      padding-bottom: 60px;
      align-items: center;
    }
    .swiper-slide {
      .swiper-slide-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        img {
          /* width: 725px; */
          width: 640px;
          /* height: 729px; */
          margin-right: 68px;
          cursor: pointer;
        }
        .introduce {
          flex-shrink: 0;
          width: 330px;
          cursor: pointer;
          .title {
            font-size: 40px;
            font-weight: 600;
          }
          .introduce-text {
            margin-top: 32px;
            margin-bottom: 32px;
            font-size: 20px;
            line-height: 1.5rem;
            &::before {
              content: "";
              display: block;
              margin-bottom: 16px;
              width: 60px;
              height: 4px;
              background-color: #015cf5;
            }
          }
          .more {
            display: inline-block;
            background: #015cf5;
            padding: 2px 32px;
            border-radius: 28px;
          }
        }
      }
    }
    .swiper-pagination {
      bottom: 14px;
      .swiper-pagination-bullet-active {
        transform: scale(1.7);
      }
      .swiper-pagination-bullet {
        background-color: #ffffff;
        opacity: 1;
      }
    }
  }
  :deep(.bottom) {
    position: relative;
    width: 1240px;
    margin: 0 auto;
    .swiper {
      padding-bottom: 40px;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      overflow: hidden;
      gap: 8px;
      .swiper-slide-item {
        width: 340px;
        background-color: #ffffff;
        color: #fff;
        box-sizing: border-box;
        overflow: hidden;
        .product-img {
          position: relative;
          width: 100%;
          height: 0px;
          padding-top: 100%;
          overflow: hidden;
          cursor: pointer;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.25s ease-in;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
        .paroduct-name {
          display: -webkit-box;
          height: 40px;
          margin: 12px 20px;
          color: #47484b;
          font-size: 16px;
          line-height: 1.2rem;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .product-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px 12px;
          .product-code {
            color: #999999;
          }
          .product-tag {
            width: 108px;
          }
        }
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet-active {
        transform: scale(1.7);
      }
      .swiper-pagination-bullet {
        background-color: #ffffff;
        opacity: 1;
      }
    }
    .btn {
      position: absolute;
      width: 32px;
      top: calc(50% - 20px);
      transform: translateY(-50%);
      cursor: pointer;
      opacity: 0.6;
      z-index: 10;
      &:hover {
        opacity: 1;
      }
    }
    .button-prev {
      left: 0;
    }
    .button-next {
      right: 0;
    }
  }
}

.mobile-terminal {
  padding-top: 16px;
  :deep(.top) {
    .swiper-wrapper {
      padding-bottom: 40px;
      align-items: center;
    }
    .swiper-slide {
      .swiper-slide-item {
        display: block;
        .title {
            padding: 0 32px;
            font-size: 20px !important;
          }
        .introduce {
          display: flex;
          width: 100vw !important;
          align-items: flex-start;
          margin-top: 32px;
          margin-right: 32px;
          .left {
            padding: 0 6px 0px 32px;
          }
          img {
            width: calc(60vw - 32px - 24px) !important;
            /* height: calc(60 * 1.165vw - 32px - 24px); */
            height: unset !important;
            margin-right: 32px;
            margin-top: 12px;
          }
          .introduce-text {
            margin-top: 0 !important;
            font-size: 14px !important;
            line-height: 1.2rem;
            &::before {
              width: 36px;
              height: 2px;
            }
          }
          .more {
            padding: 2px 24px;
            font-size: 12px;
            border-radius: 6px;
          }
        }
      }
    }
    .btn {
      width: 24px !important;
      &:hover {
        opacity: 0.6 !important;
      }
    }
    .button-prev {
      left: 4px !important;
    }
    .button-next {
      right: 4px !important;
    }
  }
  .bottom {
    width: unset !important;
    padding: 0 24px;
    .swiper-slide-item {
      width: calc(50% - 8px) !important;
      min-height: 250px;
      .product-img {
          img {
            &:hover {
              transform: scale(1.0) !important;
            }
          }
        }
      .paroduct-name {
        margin: 0 !important;
        padding: 8px !important;
        font-size: 14px !important;
        line-height: 1rem !important;
      }
      .product-code {
        padding: 4px 8px !important;
        font-size: 14px;
        color: #999;
      }
      .product-tag {
        width: 87px !important;
        margin-left: 8px;
        margin-bottom: 8px;
      }
    }
    .btn {
      width: 24px;
      &:hover {
        opacity: 0.6 !important;
      }
    }
    .button-prev {
      left: 3px;
    }
    .button-next {
      right: 3px;
    }
  }
}
</style>
