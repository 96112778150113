<template>
  <NewHome v-if="gStore.isNewVersion" />
  <OldHome v-else />
</template>
<script setup>
import NewHome from "~/components/home/new.vue"
import OldHome from "~/components/home/old.vue"
import { useGlobalStore } from "~/stores"
const { $multiPlatform } = useNuxtApp()
const gStore = useGlobalStore()
const companyName = $multiPlatform.companyName;
// seo
useHead({
title: `${companyName} - Customized design and on-demand printing`,
meta: [
  {
    name: "description",
    content: `At ${companyName}, we provide an extensive selection of custom-made promotional products known for their highly competitive pricing and industry-leading turnaround times. Explore our product catalog, which includes notebooks, trade show items, gadgets, giveaways, and promotional merchandise.`
  },
  {
    name: "keywords",
    content: `${companyName}, Rivers, Promo, promotional, customized, customizable, trade show, promo items, product sourcing, giveaway, office supplies, toys, keychains, stress balls, bags, mugs, cups, accessories, fitness, sweatbands, hats, caps, phone accessories, USB, car, auto accessories, logo, desktop, tools, gadgets, party supplies, pet supplies, household items, Power Banks, Chargers, Smart Phone, PPE, Personal Protective Equipment, Protective Gear, Disposable Gloves, Alcohol Wipes, Face Mask`
  }
]
});
</script>