import { useGlobalStore } from "~/stores";
import { getClientInfo } from '~/utils';

// 非法访问的UA黑名单
const blacklist = ['Blackbox', 'curl', 'Site24x7', 'python-requests', 'compatible']

export default defineNuxtRouteMiddleware( async (to) => {
  // 新老版本标识
  const homePageVersion = useCookie('home_page_version')
  // 进入页面生成 client_id 用于记录访客信息
  const requestClientId = useCookie('request_client_id')
  const uaflag = useCookie('uaflag2')
  if (!requestClientId.value || !uaflag.value) {
    const config = useRuntimeConfig()
    const event = useRequestEvent()
    if (event) {
      try {
        const ua = event.req.headers['user-agent']
        if (!ua) {
          return
        }
        // 黑名单过滤
        if (blacklist.some(item => ua.toLocaleLowerCase().includes(item.toLocaleLowerCase()))) {
          return
        }

        const { data } = await useAsyncData('requestClientId', () => {
          return $fetch(`${config.public.serverBaseUrl}system/anonymous/requestClientId`, {
            method: "GET",
            headers: {
              ...event.req.headers,
              ...getClientInfo(ua),
              tenantId: config.public.tenantId,
              Systemcode: "mall",
              erpTenantId: config.public.erpTenantId,
            },
            credentials: 'include'
          })
        })

        uaflag.value = 1
        requestClientId.value = data?.value?.data
      } catch (error) {}
    }
  }
  
  let flag = false
  const projectName = import.meta.env.VITE_NODE_Mall
  const gStore = useGlobalStore()
  if (projectName === 'RP') {
    /**
     * @Query isNew 通过url参数控制是否是新页面
     * 判断是否进新页面 1新页面  0老页面
     * 
     * 新老版本区分的两重逻辑：
     * 1. query参数 ?new=1/0
     * 2. cookie home_page_version 值为new/old
     * 权重： query  > request_client_id
     */
    const isNew = to.query.new
    if (isNew === '1') {
      flag = true
      homePageVersion.value = 'new'
    } else if (isNew === '0') {
      flag = false
      homePageVersion.value = 'old'
    } else if (homePageVersion.value === 'new') {
      flag = true
    } else if (homePageVersion.value === 'old') {
      flag = false
    } else {
      const modulo = (requestClientId.value || '').slice(-1)
      flag = modulo && +modulo < 2
    }
  }

  gStore.setNewVersion(flag)
});
