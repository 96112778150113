import { productInteractor } from "~/core";
import { useProductStore } from "~/stores/index";
let store, $Utils;

export const useCategory = () => {
  return {
    // 获取类别菜单
    getCategorys: async () => {
      store = store || useProductStore();
      const res = await productInteractor.categorysApi();
      let [menus, err] = res ? res : [null, null];
      return menus || [];
    },

    setCategorysInfo: async (item, nameEn,click_source) => {
      store = store || useProductStore();
      $Utils = $Utils || useNuxtApp()?.$Utils;
      store.setAccessories(item, nameEn);
      if(click_source) {
        navigateTo(
          `/category/${$Utils.replaceSpacesWithDash(item.nameEn, false)}?source=${click_source}`
        );
      }else {
        navigateTo(
          `/category/${$Utils.replaceSpacesWithDash(item.nameEn, false)}`
        );
      }
    }
  };
};
