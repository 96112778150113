import { useUserStore, useCartStore } from "~/stores";
import { authInteractor } from "~/core";
import { updateGtagConfig } from "~/plugins/gtag.client";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { getClientInfo } from '~/utils';
const jsencrypt = useJsencrypt();
const base64 = useBase64();
let lock = false;
let requests = [];
/**
 * @description axios初始化
 */

const instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  timeout: 8000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  }
});

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  async config => {
    if (config.body) {
      config.data = config.body;
    }
    if (config.query) {
      config.params = config.query;
    }
    config.headers = _processHeaders(config.headers, config.url);
    if (
      config.data &&
      config.headers["Content-Type"] ===
        "application/x-www-form-urlencoded;charset=UTF-8"
    ) {
      if (typeof config.data != "string") {
        config.data = qs.stringify(config.data);
      }
    }
    if (config.data && config.headers.encode) {
      let code = getEncryptData(1, config.data);
      code = JSON.stringify({
        paramKey: code
      });
      config.data = code;
    }
    return config;
  },
  error => {
    return Promise.resolve([null, error]);
  }
);
/**
 * 获取加密数据
 * @param {*} count
 * @param {*} configData
 * @returns
 */
function getEncryptData(count, configData) {
  count += 1;
  let code = jsencrypt.encryptData(configData);
  const len = atob(code).length;
  if (count > 3 || len % 8 === 0) {
    return code;
  }
  return getEncryptData(count, configData);
}

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  async response => {
    let data = handleData(response);
    if (Array.isArray(data)) {
      return data;
    } else {
      let subData = await data;
      if (Array.isArray(subData)) {
        return subData;
      } else {
        return [null, null];
      }
    }
  },
  error => {
    let { code, message } = error || {};
    let title = "";
    if (code === "ECONNABORTED" && message === "timeout of 8000ms exceeded") {
      title = "Connection timed out, please try again later.";
    } else {
      title = "Network connection issue, please try again later.";
    }
    Toast.fire({
      icon: "error",
      title
    });
    return Promise.resolve([null, error]);
  }
);

const handleData = ({ config, data, status, statusText }) => {
  // 若data.code存在，覆盖默认code
  let code = data && data["code"] ? data["code"] : status;
  const store = useUserStore();
  const cartStore = useCartStore();
  switch (code) {
    case "000000":
      if (config.headers.decode && data?.data) {
        data.data = JSON.parse(base64.decode(data.data));
      }
      return [data.data, null];
    case "30003": // refreshToken接口失效
      // 重置购物车数量
      cartStore.setCartCounts(0);
      store.resetAll();
      updateGtagConfig({ user_name: "", is_logged_in: "0" });
      // order-tracking页面登录失效停留在当前页面
      if (location.pathname !== "/my-account/order-tracking") {
        // 登录失效后跳转到登录页
        setTimeout(() => {
          location.href = "/account/signin?expire=1";
        }, 500);
      }
      return [null, data];

    case "20002": // 登录接口失效
      if (!lock) {
        lock = true;

        let params = {
          originAccessToken: store.accessToken,
          originRefreshToken: store.refreshToken
        };
        return authInteractor
          .getTokenRepeatApi(params)
          .then(res => {
            let [data, err] = res;
            if (!err) {
              store.setAccessToken(data.accessToken);
              store.setRefreshToken(data.refreshToken);
              config.headers.tokenId = data.accessToken;

              requests.forEach(cb => cb(data.accessToken));
              requests = [];
              if (config.url.match("checkToken")) {
                config.data.originAccessToken = data.accessToken;
                config.data.originRefreshToken = data.refreshToken;
              }
              return instance(config);
            }
          })
          .catch(res => {
            console.log("catch res", res);
          })
          .finally(() => {
            lock = false;
          });
      } else {
        return new Promise(resolve => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push(async token => {
            config.headers["tokenId"] = token;
            let res = await instance(config);
            resolve(res);
          });
        });
      }
    case "404":
      navigateTo("/404");
      break
    // 超出箱数 200 箱错误码
    case "OVER_BOX_LIMIT":
      return [null, "OVER_BOX_LIMIT"];

    default:
      if (data.desc !== "tokenId cannot be empty") {
        Toast.fire({
          icon: "error",
          title: data.desc
        });
      }

      return [null, data];
  }
};

/**
 * 网络请求方法
 * @param obj 请求参数
 * @returns 响应结果
 */

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  systemCode: "mall"
};

function _processHeaders(headers, url) {
  const store = useUserStore();
  let clientInfo = {}
  try {
    if (window) {
      clientInfo = getClientInfo(navigator.userAgent)
    }
  } catch (e) {}
  headers = {
    ...DEFAULT_HEADERS,
    ...headers,
    ...clientInfo,
    tokenId: url.match("refreshToken") ? "" : store.accessToken,
    tenantId: import.meta.env.VITE_TENANT_ID,
    erpTenantId: import.meta.env.VITE_ERPTENANT_ID,
    lang: "en"
  };
  if (headers.noTokenId) {
    delete headers.tokenId;
  }
  return headers;
}

export default instance;

export const baseAxios = axios.create()

// 临时的处理方案，需要对kits中方法进行处理
// function judgeDevice(){
//   return 'pc'
//   try {
//     if(window) {
//       const ua = navigator.userAgent.toLowerCase();
//       if (/ipad|ipod/.test(ua)) {
//         return 'ipad';
//       } else if (/android|iphone/.test(ua)) {
//         return 'mobile';
//       }
//       return 'pc';
//     }
//   } catch (e) {

//   }

// };